import React from 'react';

const Card = ({ className, children }) => (
  <div
    className={`p-8 rounded-lg border-2 border-solid border-gray-400 bg-white h-full flex flex-col justify-between ${className}`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }}
  >
    {children}
  </div>
);

export default Card;
